// Toggle between http and https depending if ssl key is available
const DEFAULT_PROTOCOL = 'https';

export const build = {
  API_HOST: DEFAULT_PROTOCOL + '://api.config.dev.ubicquia.com/api/',
  SITE_URL: 'http://swagger.config.dev.ubicquia.com/',
  LOGO: 'logo',

  // Options: en (English)
  DEFAULT_LANG: 'en',
};
